<template>

  <div>
    <v-app-bar flat style="height:74px">
      <v-row no-gutters>
        <v-col md="4" 
               :offset="$mq=='sm'?0:4" 
               style="padding-top:25px">
          <v-text-field v-model="filter">
            <v-icon slot="append">
              mdi-magnify
            </v-icon>
          </v-text-field>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-tabs v-model="tab" center-active dark>
      <v-tab v-for="(x,i) in 26" :key="'key-'+i">
        {{ letter(i) }}
      </v-tab>
    </v-tabs>

    <v-container fluid>
      <TopicsList :filter="filter"
                  :letter="letter(tab)" />
    </v-container>
  </div>

</template>

<script>

  import TopicsList from '../components/topic/Topics'

  export default {
    name: 'Topics',

    components: {
      TopicsList
    },

    mounted(){
    },

    methods: {
      letter(i)
      {
        return String.fromCharCode(96 + i + 1)
      }
    },

    data: () => ({
      filter: '',
      tab: 0
    }),

    computed: {}
  }
</script>

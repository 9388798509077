<template>
  <div>
    <Header title="Favourites" />
    <div v-if="hasFavs">
        <v-tabs
          background-color="grey"
          center-active
          dark
          v-model="tab"
        >
          <v-tab v-if="hasBooks" href="#books">
            <v-icon class="mr-3">mdi-book</v-icon> 
            Books
          </v-tab>
          <v-tab v-if="hasIssues" href="#issues">
            <v-icon class="mr-3">mdi-newspaper-variant-outline</v-icon> 
            Magazines
          </v-tab>
          <v-tab v-if="hasArticles" href="#articles">
            <v-icon class="mr-3">mdi-newspaper</v-icon> 
            Articles
          </v-tab>
          <v-tab v-if="hasNotes" href="#notes">
            <v-icon class="mr-3">mdi-note-multiple</v-icon> 
            Notes
          </v-tab>
          <v-tab v-if="hasQuotes" href="#quotes">
            <v-icon class="mr-3">mdi-bookmark</v-icon> 
            Quotes
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">

          <!-- Books. -->
          <v-tab-item v-if="hasBooks" value="books">
            <v-container fluid>
                <v-row align="start">
                  <v-col cols="6" md="2" v-for="(book) in favs.books" :key="book.id">
                    <BookCard :book="book" />
                  </v-col>
                </v-row>
            </v-container>
          </v-tab-item>

          <!-- Magazine Issues. -->
          <v-tab-item v-if="hasIssues" value="issues">
            <v-container fluid>
                <v-row align="start">
                  <v-col cols="6" md="2" 
                         v-for="(issue) in favs.issues" 
                         :key="issue.id">
                    <IssueCard :issue="issue" 
                               :height="250" />
                  </v-col>
                </v-row>
            </v-container>
          </v-tab-item>

          <!-- Articles. -->
          <v-tab-item v-if="hasArticles" value="articles">
            <v-container fluid>
                <ArticlesList :articles="favs.articles"
                              with-subtitle />
            </v-container>
          </v-tab-item>
          
          <!-- Notes. -->
          <v-tab-item v-if="hasNotes" value="notes">
            <v-container fluid>
                <Note v-for="note in favs.notes" 
                      :key="note.id"
                      :note="note" />
            </v-container>
          </v-tab-item>

          <!-- Quotes. -->
          <v-tab-item v-if="hasQuotes" value="quotes">
            <v-container fluid>
                <Quote v-for="quote in sortedQuotes" 
                       :key="quote.id"
                       :quote="quote" 
                       :zoom="false"
                       without-menu>
                  <template v-slot:details>
                    <div>
                      <v-icon class="mr-2">mdi-book</v-icon> 
                      <strong class="grey--text">{{ quote.owner.title }}</strong>
                    </div>
                  </template>
                </Quote>
            </v-container>
          </v-tab-item>

        </v-tabs-items>
    </div>

    <v-container v-else>
      <Message title="No favourites here yet!"/>
    </v-container>

  </div>
</template>

<script>
  import _ from 'lodash'
  import Api from '@/api'
  import utility from '@/mixins/utility'
  
  import ArticlesList from '@/components/article/List'
  import Header from '@/components/Header'
  import IssueCard from '@/components/issue/Card'
  import Message from '@/components/Message'
  import Note from '@/components/note/Note'
  import Quote from '@/components/quote/Quote'

  export default {
    name: 'EmbedFavouritesPage',

    mixins: [ utility ],

    components: {
      ArticlesList,
      Header,
      IssueCard,
      Message,
      Note,
      Quote
    },

    data: () => ({
        favs: {
            articles: [],
            books: [],
            issues: [],
            notes: [],
            quotes: []
        },
        tab: null
    }),

    mounted()
    {
        this.loadFavourites()
    },

    computed:
    {
        hasArticles()
        {
            return !_.isEmpty(this.favs.articles)
        },

        hasBooks()
        {
            return !_.isEmpty(this.favs.books)
        },

        hasFavs()
        {
            return (this.hasBooks || this.hasNotes || this.hasQuotes)
        },

        hasIssues()
        {
            return !_.isEmpty(this.favs.issues)
        },

        hasNotes()
        {
            return !_.isEmpty(this.favs.notes)
        },

        hasQuotes()
        {
            return !_.isEmpty(this.favs.quotes)
        },

        sortedQuotes()
        {
          var sorted = _.sortBy(this.favs.quotes, 'book.title')

          return sorted
        }
    },

    methods:
    {

        loadFavourites()
        {
          this.$store.commit('setLoading')

            Api.favourites.list({ with:['bookshelves'] })
               .then((res)=>{
                this.favs = res.data

                this.$store.commit('setLoading', false)
               })
        },
    }
  }
</script>

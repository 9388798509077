<template>
  <div>
    <Header :title="tag.name.toUpperCase()" />
    <div v-if="hasItems">
        <v-tabs
          background-color="grey"
          center-active
          dark
          v-model="tab"
        >
          <v-tab v-if="hasBooks">
            <v-icon class="mr-3">mdi-book</v-icon> 
            Books
          </v-tab>
          <v-tab v-if="hasIssues">
            <v-icon class="mr-3">mdi-newspaper-variant-outline</v-icon> 
            Magazines
          </v-tab>
          <v-tab v-if="hasQuotes">
            <v-icon class="mr-3">mdi-bookmark</v-icon> 
            Quotes
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="hasBooks">
            <v-container fluid>
                <v-row align="start">
                  <v-col cols="6" md="2" v-for="(book) in tag.books.data" :key="book.id">
                    <BookCard :book="book" 
                              @click="onBookClick"
                              @contextmenu="onContextMenu"/>
                  </v-col>
                </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="hasIssues">
            <v-container fluid>
              <v-row align="start">
                <v-col cols="6" md="2" 
                       v-for="issue in tag.issues.data" 
                       :key="issue.id">
                  <IssueCard :issue="issue"
                             :height="250" 
                             @click="onIssueClick"/>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="hasQuotes">
            <v-container fluid>
                <Quote v-for="quote in tag.quotes.data" 
                       :key="quote.id"
                       :quote="quote" 
                       @click="onQuoteClick"
                       book-details />
            </v-container>
          </v-tab-item>
        </v-tabs-items>
    </div>

    <v-container v-else>
        <v-alert text color="info">
          <h3 class="headline">No content here (yet!)</h3>
        </v-alert>
    </v-container>

    <ActionButton>
      <v-btn dark fab @click="addTag">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn dark fab @click="editTag">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn dark fab @click="showDeleteModal">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
      <v-btn dark fab @click="copyEmbedUrl">
        <v-icon>mdi-xml</v-icon>
      </v-btn>
      <v-btn dark fab @click="exportData">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </ActionButton>

    <TagModal ref="tagModalAdd" 
              @save="onTagModalSave"/>
    
    <TagModal ref="tagModalEdit" 
              :tag-id="tag.id" 
              @save="onTagModalSave"/>

    <DeleteTagModal ref="tagModalDelete"
                    :tag="tag"
                    :auto-close="false"
                    @save="onTagModalDelete"/>

    <!-- context menu -->
    <BookContextMenu ref="contextMenu"/>

  </div>
</template>

<script>
  import _ from 'lodash'
  import Api from '../api'

  import utility from '@/mixins/utility'
  
  import ActionButton from '@/components/ActionButton'
  import BookContextMenu from '@/components/book/context-menu/Menu'
  import Header from '@/components/Header'
  import IssueCard from '@/components/issue/Card'
  import Quote from '@/components/quote/Quote'
  import TagModal from '@/components/tag/TagModal'
  import DeleteTagModal from '@/components/tag/DeleteModal'

  export default {
    name: 'TagPage',

    mixins: [ utility ],

    components: {
      ActionButton,
      BookContextMenu,
      Header,
      IssueCard,
      Quote,
      TagModal,
      DeleteTagModal
    },

    data: () => ({
        tag: {
          name: '',
          books: { data:[] },
          quotes: { data:[] }
        },
        tab: null
    }),

    mounted()
    {
        this.loadTag()
    },

    computed:
    {
        hasBooks()
        {
            return !_.isEmpty(this.tag.books)
                   && !_.isEmpty(this.tag.books.data)
        },

        hasItems()
        {
            return (this.hasBooks || this.hasQuotes || this.hasIssues)
        },

        hasIssues()
        {
            return this.tag.issues && !_.isEmpty(this.tag.issues.data)
        },

        hasQuotes()
        {
            return this.tag.quotes && !_.isEmpty(this.tag.quotes.data)
        }
    },

    methods:
    {
        addTag()
        {
          this.$refs.tagModalAdd.show()
        },
        editTag()
        {
          this.$refs.tagModalEdit.show()
        },
        exportData()
        {
          this.$store.commit('setLoading')

          Api.exports.generate('tag', { meta:this.$route.params.tagId })
                     .then(()=>{
                      this.$router.push({ name: 'settings' })
                     })
                     .finally(()=>{
                      this.$store.commit('setLoading', false)
                     })
        },
        loadTag()
        {
          let tagId = this.$route.params.tagId

          this.$store.commit('setLoading')

          Api.tags.one(tagId)
             .then((res)=>{
              
              this.tag = res.data.data

              // set page title
              document.title = `${this.tag.name} - Tags`

              this.$store.commit('setLoading', false)
             })
        },
        onBookClick(book)
        {
            this.$router.push({ name: 'book', params: { bookId: book.id } })
        },
        onContextMenu(e, book)
        {
          this.$refs.contextMenu.show(e, book)
        },
        onIssueClick(issue)
        {
          this.$router.push({ name: 'issue', params: { issueId: issue.id } })
        },
        onQuoteClick(quote)
        {
            /**
             * @todo check that quote is actually owned by a book.
             */
            this.$router.push({ name: 'book', params: { bookId: quote.book_id } })
        },
        onTagModalSave(tag)
        {
          if( tag.id != this.tag.id )
            this.$router.push({ name: 'tag', params: { tagId: tag.id } })
          
          this.tag = tag
        },
        showDeleteModal()
        {
          this.$refs.tagModalDelete.show() 
        },
        onTagModalDelete()
        {
          window.setTimeout(()=>{
            this.$router.push({ name: 'tags' })
          }, 1500)
        },
        copyEmbedUrl()
        {
          const embedUrl = location.href + '/embed'
          this.copyToClipboard(embedUrl)
        }
    }
  }
</script>

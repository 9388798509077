<template>
    <div>
        <Header>
            Chatbot
        </Header>

        <iframe src="https://app.customgpt.ai/projects/28565/ask-me-anything?embed=1&shareable_slug=52814a8d1367cfed4a28332e3739c344"
                class="iframe"/>
    </div>
</template>

<script>
import Header from '../components/Header'

export default {
    name: 'ChatbotPage',

    components: {
      Header
    },
}
</script>
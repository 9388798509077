<template>

  <div>
    
    <Header title="Genres"></Header>

    <v-list two-line v-if="hasGenres">

      <div v-for="(genres,letter) in groupedGenres" 
           :key="letter">

        <v-subheader>
          {{ letter }}
        </v-subheader>
      
        <v-list-item 
          v-for="genre in genres" 
          :key="genre.id"
          @click="goTo(genre)">

          <v-list-item-content>
            <v-list-item-title>
              {{ genre.name.toUpperCase() }}
              <v-chip class="ml-2" small 
                      v-html="genre.books_count"></v-chip>
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>

      </div>

    </v-list>

    <v-alert text color="info" v-else>
      <h3 class="headline">No genres here (yet!)</h3>
    </v-alert>

  </div>

</template>

<script>

  import _ from 'lodash'
  import Api from '../api'
  import InfiniteScroll from '../mixins/infinite-scroll'

  import Header from '../components/Header'

  export default {
    name: 'Genres',

    components: {
      Header
    },

    mixins: [InfiniteScroll],

    mounted(){
      this.loadGenres()
    },

    methods: {

      goTo(genre)
      {
        this.$router.push({ name: 'genre', params: { genreId: genre.id } })
      },

      /**
       * Load genres to list via API.
       */
      loadGenres()
      {
        this.$store.commit('setLoading')

        var page = this.pagination.current_page

        Api.genres.list({ page:page }).then((res)=>{
          var merged = this.genres.concat(res.data.data)
          
          this.genres = merged
          this.groupedGenres = _.groupBy(merged,(genre)=> {
                    return genre.name.toUpperCase().substring(0,1)
                  })

          this.pagination = res.data.meta

          this.$store.commit('setLoading', false)
        })
      }

    },

    data: () => ({
      genres: [],
      groupedGenres: {},
      pagination: { current_page:1 }
    }),

    computed: {
      hasGenres()
      {
        return !_.isEmpty(this.groupedGenres)
      }
    },

    watch: {
      infiniteScroll: {
         handler(infiniteScroll){
          if (infiniteScroll.bottom)
          {
            this.pagination.current_page++
            this.loadGenres()
          }
         },
         deep: true
      }
    }
  }
</script>

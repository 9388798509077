<template>
  <v-container fluid>
    
    <v-row align="start">  
      <v-col cols="12" md="4">
        <WidgetStats/>
      </v-col>
      <v-col cols="12" md="4">
        <WidgetReadingBooks/>
        <WidgetReadBooks/>
        <WidgetChart/>
      </v-col>
      <v-col cols="12" md="4">
        <WidgetLastBooks/>
        <WidgetLastIssues/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import WidgetChart from '../components/widgets/Chart'
import WidgetLastBooks from '../components/widgets/LastBooks'
import WidgetLastIssues from '../components/widgets/LastIssues'
import WidgetReadBooks from '../components/widgets/ReadBooks'
import WidgetReadingBooks from '../components/widgets/ReadingBooks'
import WidgetStats from '../components/widgets/Stats'

  export default {
    name: 'HomePage',

    components: {
        WidgetChart,
        WidgetLastBooks,
        WidgetLastIssues,
        WidgetReadBooks,
        WidgetReadingBooks,
        WidgetStats
    },

    data: () => ({
      
    }),
  }
</script>

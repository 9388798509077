<template>

  <div>
    
    <Header :title="magazine.title"
            :subtitle="magazine.publisher"></Header>

    <v-container fluid>

      <v-row align="start" v-if="loading && !issues.length">
        <v-col cols="6" md="2" v-for="i in 6" :key="i">
          <v-sheet
            color="grey lighten-4"
            class="pa-3"
          >
            <v-skeleton-loader type="card" class="w-100"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>

      <Message v-if="!loading && !issues.length">
        No issues for this magazine yet.
      </Message>
      
      <v-row align="start">
      
        <v-col cols="6" md="2" v-for="(issue) in sortedIssues" :key="issue.id">
      
          <IssueCard :issue="issue" :height="250" @click="goTo"/>
      
        </v-col>
      
      </v-row>

    </v-container>

    <ActionButton icon="mdi-plus">
      <v-btn fab dark @click="openMagazineModal">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn fab dark @click="openIssueModal">
        <v-icon>mdi-newspaper-variant-multiple-outline</v-icon>
      </v-btn>
    </ActionButton>

    <MagazineModal ref="magazineModal" 
                   button-class="d-none"
                   @save="onMagazineSave"
                   :magazine-id="magazine.id"/>

    <IssueModal ref="issueModal"
                :magazine-id="magazine.id"
                @save="onIssueSave"/>

  </div>

</template>

<script>

  const axios = require('axios')

  import _ from 'lodash'
  import Api from '../api'

  import InfiniteScroll from '../mixins/infinite-scroll'

  import ActionButton from '../components/ActionButton'
  import Header from '../components/Header'
  import IssueCard from '../components/issue/Card'
  import IssueModal from '../components/issue/Modal'
  import MagazineModal from '../components/magazine/Modal'
  import Message from '../components/Message'

  export default {
    name: 'Magazine',

    components: {
      ActionButton,
      Header,
      IssueCard,
      IssueModal,
      MagazineModal,
      Message
    },

    mixins: [InfiniteScroll],

    mounted(){
      this.load()
    },

    methods: {

      goTo(issue)
      {
        this.$router.push({ name: 'issue', params: { issueId: issue.id } })
      },

      /**
       * Load magazine details and issues via API.
       */
      load()
      {
        this.loading = true

        //var page = (this.meta.current_page + 1)

        let magazineId = this.$route.params.magazineId

        axios.all([
            Api.magazines.issues(magazineId),
            Api.magazines.one(magazineId)
          ])
          .then(axios.spread((res, res2)=>{

            this.issues = this.issues.concat(res.data.data)
            
            this.meta = res.data.meta

            this.magazine = res2.data.data

            this.loading = false

          }))
      },

      /**
       * When a new issue is saved.
       */
      onIssueSave(issue)
      {
        this.issues.push(issue)
      },

      /**
       * When magazine is updated via modal.
       */
      onMagazineSave(magazine)
      {
        this.magazine = magazine
      },

      /**
       * Open issue lookup modal.
       */
      openIssueModal()
      {
        this.$refs.issueModal.show()
      },

      /**
       * Open magazine modal.
       */
      openMagazineModal()
      {
        this.$refs.magazineModal.show()
      },

      reset()
      {
        this.issues = []
        
        this.meta = { current_page:0, last_page:0 }
      }

    },

    data: () => ({
      issues: [],
      loading: false,
      magazine: {},
      meta: {
        current_page: 0,
        last_page: 0
      }
    }),

    computed: {
      hasMore()
      {
        return (this.meta.current_page < this.meta.last_page);
      },
      sortedIssues()
      {
        return _.orderBy(this.issues, 'published_at', 'desc')
      }
    },

    watch: {
      infiniteScroll: {
         handler(infiniteScroll){
          if (infiniteScroll.bottom && this.hasMore)
            this.loadIssues()
         },
         deep: true
      },
      loading(status)
      {
        this.$store.commit('setLoading', status)
      }
    }
  }
</script>

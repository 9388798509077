<template>

  <div>
    
    <Header title="Magazines"></Header>

    <v-container fluid>

      <v-row align="start" v-if="loading && !hasMagazines">
        <v-col cols="6" md="2" v-for="i in 6" :key="i">
          <v-sheet
            color="grey lighten-4"
            class="pa-3"
          >
            <v-skeleton-loader type="card" class="w-100"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row align="start" v-else-if="hasMagazines">      
        <v-col cols="6" md="2" 
               v-for="(magazine) in magazines" 
               :key="magazine.id">

          <MagazineCard :magazine="magazine" 
                        :height="250" 
                        @click="goTo"/>
      
        </v-col>
      </v-row>


      <v-alert text color="info" v-else>
        No magazines here (yet!)
      </v-alert>
    </v-container>

    <ActionButton icon="mdi-plus">
      <v-btn fab dark @click="openMagazineModal">
        <v-icon>mdi-newspaper-variant-outline</v-icon>
      </v-btn>
      <v-btn fab dark @click="openIssueModal">
        <v-icon>mdi-newspaper-variant-multiple-outline</v-icon>
      </v-btn>
    </ActionButton>

    <MagazineModal ref="magazineModal" 
                   button-class="d-none"
                   @save="onMagazineSave"/>

    <IssueModal ref="issueModal"/>

  </div>

</template>

<script>

  import _ from 'lodash'
  import Api from '../api'
  import InfiniteScroll from '../mixins/infinite-scroll'
  import Utils from '../utils'

  import ActionButton from '../components/ActionButton'
  import Header from '../components/Header'
  import IssueModal from '../components/issue/Modal'
  import MagazineCard from '../components/magazine/Card'
  import MagazineModal from '../components/magazine/Modal'

  export default {
    name: 'Magazines',

    components: {
      ActionButton,
      Header,
      IssueModal,
      MagazineCard,
      MagazineModal
    },

    mixins: [
      InfiniteScroll,
      Utils
    ],

    mounted(){
      this.loadMagazines()
    },

    methods: {

      goTo(magazine)
      {
        if( magazine )
          this.$router.push({ name: 'magazine', params: { magazineId:magazine.id } })
      },

      /**
       * Load magazines to list via API.
       */
      loadMagazines()
      {
        this.$store.commit('setLoading')

        this.loading = true

        var page = this.pagination.current_page

        Api.magazines.list({ page:page }).then((res)=>{
          var merged = this.magazines.concat(res.data.data)
          
          this.magazines = _.sortBy(merged, ['title'])
          
          this.pagination = res.data.meta

          this.loading = false

          this.$store.commit('setLoading', false)
        })
      },

      /**
       * When magazine modal is saved refresh magazines.
       */
      onMagazineSave(magazine)
      {
        this.goTo(magazine)
      },

      openIssueModal()
      {
        this.$refs.issueModal.show()
      },

      openMagazineModal()
      {
        this.$refs.magazineModal.show()
      }

    },

    data: () => ({
      loading: false,
      magazines: [],
      pagination: { current_page:1 }
    }),

    computed: {
      hasMagazines()
      {
        return !_.isEmpty(this.magazines)
      }
    },

    watch: {
      infiniteScroll: {
         handler(infiniteScroll){
          if (infiniteScroll.bottom)
          {
            this.pagination.current_page++
            this.loadMagazines()
          }
         },
         deep: true
      }
    }
  }
</script>

<template>

  <div>

    <Header>
      <template>
        <v-chip class="ma-2" dark v-html="genre.books_count"></v-chip>{{ genre.name }} 
      </template>
    </Header>

    <v-container fluid>
      
      <v-row align="start" v-if="books.length">
      
        <v-col cols="6" md="2" v-for="(book) in books" :key="book.id">
      
          <BookCard :book="book" 
                    @click="goTo" 
                    @contextmenu="onContextMenu" />
      
        </v-col>
      
      </v-row>

      <Message v-else title="No books here (yet!)."/>

    </v-container>

    <!-- context menu -->
    <BookContextMenu ref="contextMenu"/>

  </div>

</template>

<script>

  import Api from '../api'

  import BookContextMenu from '@/components/book/context-menu/Menu'
  import Header from '../components/Header'
  import Message from '../components/Message'

  export default {
    name: 'Genre',

    components: {
      BookContextMenu,
      Header,
      Message
    },

    mounted(){
      this.loadBooks()
    },

    methods: {

      goTo(book)
      {
        this.$router.push({ name: 'book', params: { bookId: book.id } })
      },

      /**
       * Load books to list via API.
       */
      loadBooks()
      {
        let genreId = this.$route.params.genreId

        this.$store.commit('setLoading')

        Api.genres.one(genreId, { with:['bookshelves'] })
                  .then((res)=>{
                    // res.data.links
                    this.genre = res.data.data

                    this.$store.commit('setLoading', false)
                  })
      },

      onContextMenu(e, book)
      {
        this.$refs.contextMenu.show(e, book)
      },

    },

    data: () => ({
      genre: {}
    }),

    computed: {
      books()
      {
        return this.genre.books ? this.genre.books.data : []
      }
    }
  }
</script>

<template>

  <div>

    <Header v-if="topic.name" :title="topic.name.toUpperCase()">
      <template v-if="wikipediaUrl">
        <v-btn class="ml-3"
               @click="open(wikipediaUrl)">
          <v-icon>mdi-wikipedia</v-icon>
        </v-btn>
      </template>
    </Header>

    <v-tabs v-model="tab" 
            background-color="grey"
            center-active
            dark>
      <v-tab key="books">
        <v-icon class="mr-3">mdi-books</v-icon>
        Books
        <v-chip class="ml-3">
          {{ books.length }}
        </v-chip>
      </v-tab>
      <v-tab key="issues">
        <v-icon class="mr-3">mdi-newspaper-variant-outline</v-icon>
        Issues
        <v-chip class="ml-3">
          {{ issues.length }}
        </v-chip>
      </v-tab>
      <v-tab key="articles">
        <v-icon class="mr-3">mdi-newspaper</v-icon>
        Articles
        <v-chip class="ml-3">
          {{ articles.length }}
        </v-chip>
      </v-tab>
      <v-tab key="quotes">
        <v-icon class="mr-3">mdi-bookmark</v-icon>
        Quotes 
        <v-chip class="ml-3">
          {{ quotes.length }}
        </v-chip>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- Books -->
      <v-tab-item key="books">
        <v-row v-if="books.length" class="px-3">
          <v-col cols="6" md="2"
                 v-for="book in books"
                 v-bind:key="book.id">
            <BookCard :book="book"
                      @click="onBookClick"
                      @contextmenu="onContextMenu"/>
          </v-col>
        </v-row>
        <v-container v-else>
          <Message>No books for this topic yet.</Message>
        </v-container>
      </v-tab-item>
      <!-- Issues -->
      <v-tab-item key="issues">
        <v-row v-if="issues.length" class="px-3">
          <v-col cols="6" md="2"
                 v-for="issue in issues"
                 v-bind:key="issue.id">
            <IssueCard :issue="issue"
                       :height="250"
                       @click="onIssueClick"/>
          </v-col>
        </v-row>
        <v-container v-else>
          <Message>No issues for this topic yet.</Message>
        </v-container>
      </v-tab-item>
      <!-- Articles -->
      <v-tab-item key="articles">
        <v-row v-if="articles.length" class="px-3">
          <v-col cols="12">
            <ArticlesList :articles="articles"/>
          </v-col>
        </v-row>
        <v-container v-else>
          <Messag>No articles for this topic yet.</Messag>
        </v-container>
      </v-tab-item>
      <!-- Quotes -->
      <v-tab-item key="quotes">
        <v-container v-if="quotes.length">
          <Quote v-for="quote in quotes" 
                 :key="quote.id"
                 :quote="quote" 
                 :book-details="true"
                 @click="onQuoteClick" />
        </v-container>
        <v-container v-else>
          <Message>No quotes for this topic yet.</Message>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <ActionButton>
      <!-- Show or hide -->
      <v-btn fab dark @click="openHideDialog">
        <v-icon>mdi-eye-off</v-icon>
      </v-btn>
      <!-- Forward -->
      <v-btn fab dark @click="openForwardDialog">
        <v-icon>mdi-call-merge</v-icon>
      </v-btn>
      <!-- Network -->
      <NetworkActionButton :model-id="topicId"
                           model-type="topic"/>
    </ActionButton>

    <HideDialog :topicId="topicId" 
                ref="hideDialog"
                @submit="onHide" />

    <ForwardDialog :topicId="topicId"
                   ref="forwardDialog" 
                   @submit="onHide" />

    <Loading :status="loading"/>

    <!-- context menu -->
    <BookContextMenu ref="contextMenu"/>

  </div>

</template>

<script>

  import Api from '../api'
  import ActionButton from '../components/ActionButton'
  import ArticlesList from '../components/article/List'
  import BookContextMenu from '@/components/book/context-menu/Menu'
  import ForwardDialog from '../components/topic/ForwardDialog'
  import Header from '../components/Header'
  import HideDialog from '../components/topic/HideDialog'
  import IssueCard from '../components/issue/Card'
  import Loading from '../components/Loading'
  import Message from '../components/Message'
  import NetworkActionButton from '../components/network/ActionButton'
  import Quote from '../components/quote/Quote'

  export default {
    name: 'Topic',

    components: {
      ActionButton,
      ArticlesList,
      BookContextMenu,
      ForwardDialog,
      Header,
      HideDialog,
      IssueCard,
      Loading,
      Message,
      NetworkActionButton,
      Quote
    },

    mounted(){
      this.loadTopic()
    },

    methods: {
      loadTopic()
      {
        this.loading = true

        const params = {
          with: [ 'articles', 'books', 'bookshelves', 'issues', 'quotes' ]
        }

        Api.topics.one(this.topicId, params)
                  .then((res)=>{
                    
                    this.topic = res.data.data
                    
                    this.loading = false

                    // set page title
                    document.title = `${this.topic.name} - Topics`

                  })
                  .catch((err)=>{
                    
                    console.error(err)
                    
                    this.loading = false

                  })
      },
      onBookClick(book)
      {
        this.$router.push({ name: 'book', params: { bookId: book.id } })
      },
      onContextMenu(e, book)
      {
        this.$refs.contextMenu.show(e, book)
      },
      onHide()
      {
        window.setTimeout(()=>{
          this.$router.push({ name: 'topics' })
        }, 2000)
      },
      onIssueClick(issue)
      {
        this.$router.push({ name: 'issue', params: { issueId: issue.id } })
      },
      onQuoteClick(quote)
      {
        /**
         * @todo check if quote owner is actually a book!
         */
        this.$router.push({ name: 'book', params: { bookId: quote.owner.id } })
      },
      open(url)
      {
        window.open(url)
      },
      openForwardDialog()
      {
        this.$refs.forwardDialog.show()
      },
      openHideDialog()
      {
        this.$refs.hideDialog.show()
      }
    },

    data: () => ({
      tab: 'books',
      hideDialog: false,
      loading: false,
      topic: {
        articles: {},
        books: {},
        issues: {},
        quotes: {}
      },
    }),

    computed: {
      articles()
      {
         if(this.topic.articles.data)
          return this.topic.articles.data

        return []
      },
      books()
      {
         if(this.topic.books.data)
          return this.topic.books.data

        return []
      },
      issues()
      {
         if(this.topic.issues.data)
          return this.topic.issues.data

        return []
      },
      quotes()
      {
        if(this.topic.quotes.data)
          return this.topic.quotes.data

        return []
      },
      topicId()
      {
        let topicId = this.$route.params.topicId

        if(topicId)
          topicId = parseInt(topicId)

        return topicId
      },
      wikipediaUrl()
      {
        if(this.topic.metadata)
          return this.topic.metadata.wikipedia_url
        return ''
      }
    }
  }
</script>

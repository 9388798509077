<template>

  <div>

    <Header title="Tags" />

    <TagsList @click="onClick"
              ref="tagsList"/>

    <ActionButton icon="mdi-plus">
      <v-btn fab dark @click="openTagModal">
        <v-icon>mdi-label</v-icon>
      </v-btn>
    </ActionButton>

    <TagModal ref="tagModal" @save="onModalSave"/>

  </div>

</template>

<script>

  import ActionButton from '../components/ActionButton'
  import Header from '../components/Header'
  import TagModal from '../components/tag/TagModal'
  import TagsList from '../components/tag/List'

  export default {
    name: 'Tags',

    components: {
      ActionButton,
      Header,
      TagModal,
      TagsList
    },

    mounted(){},

    methods: {

      onClick(tag)
      {
        this.$router.push({ name: 'tag', params: { tagId: tag.id } })
      },

      onModalSave()
      {
        this.$refs.tagsList.loadTags()
      },

      openTagModal()
      {
        this.$refs.tagModal.show()
      }

    },

    data: () => ({}),
  }
</script>

<template>
  <div>
    <Header title="Favourites" />
    <div v-if="hasFavs">
        <v-tabs
          background-color="grey"
          center-active
          dark
          v-model="tab"
        >
          <v-tab v-if="hasBooks" href="#books">
            <v-icon class="mr-3">mdi-book</v-icon> 
            Books
          </v-tab>
          <v-tab v-if="hasIssues" href="#issues">
            <v-icon class="mr-3">mdi-newspaper-variant-outline</v-icon> 
            Magazines
          </v-tab>
          <v-tab v-if="hasArticles" href="#articles">
            <v-icon class="mr-3">mdi-newspaper</v-icon> 
            Articles
          </v-tab>
          <v-tab v-if="hasNotes" href="#notes">
            <v-icon class="mr-3">mdi-note-multiple</v-icon> 
            Notes
          </v-tab>
          <v-tab v-if="hasQuotes" href="#quotes">
            <v-icon class="mr-3">mdi-bookmark</v-icon> 
            Quotes
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">

          <!-- Books. -->
          <v-tab-item v-if="hasBooks" value="books">
            <v-container fluid>
                <v-row align="start">
                  <v-col cols="6" md="2" v-for="(book) in favs.books" :key="book.id">
                    <BookCard :book="book" 
                              @click="onBookClick"
                              @contextmenu="onContextMenu"/>
                  </v-col>
                </v-row>
            </v-container>
          </v-tab-item>

          <!-- Magazine Issues. -->
          <v-tab-item v-if="hasIssues" value="issues">
            <v-container fluid>
                <v-row align="start">
                  <v-col cols="6" md="2" 
                         v-for="(issue) in favs.issues" 
                         :key="issue.id">
                    <IssueCard :issue="issue" 
                               :height="250" 
                               @click="onIssueClick"/>
                  </v-col>
                </v-row>
            </v-container>
          </v-tab-item>

          <!-- Articles. -->
          <v-tab-item v-if="hasArticles" value="articles">
            <v-container fluid>
                <ArticlesList :articles="favs.articles"
                              with-subtitle />
            </v-container>
          </v-tab-item>
          
          <!-- Notes. -->
          <v-tab-item v-if="hasNotes" value="notes">
            <v-container fluid>
                <Note v-for="note in favs.notes" 
                      :key="note.id"
                      :note="note" 
                      @click="onNoteClick"/>
            </v-container>
          </v-tab-item>

          <!-- Quotes. -->
          <v-tab-item v-if="hasQuotes" value="quotes">
            <v-container fluid>
                <Quote v-for="quote in sortedQuotes" 
                       :key="quote.id"
                       :quote="quote" 
                       :book-details="true"
                       :zoom="false">
                  <template v-slot:actions>
                    <v-list-item @click="openBook(quote)">
                      <v-list-item-avatar>
                        <v-avatar size="32px" tile>
                          <v-icon>mdi-book</v-icon>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-title>
                        OPEN BOOK
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </Quote>
            </v-container>
          </v-tab-item>

        </v-tabs-items>
    </div>

    <v-container v-else>
      <Message title="No favourites here yet!"/>
    </v-container>

    <ActionButton>
      <v-btn dark fab @click="copyEmbedUrl">
        <v-icon>mdi-xml</v-icon>
      </v-btn>
      <v-btn dark fab @click="exportData">
        <v-icon>mdi-export</v-icon>
      </v-btn>
      <v-btn fab dark @click="openClearAllModal">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </ActionButton>

    <ClearAllModal :model-type="tab" 
                   ref="clearAllModal"
                   @clear="loadFavourites" />

    <!-- context menu -->
    <BookContextMenu ref="contextMenu"/>

  </div>
</template>

<script>
  import _ from 'lodash'
  import Api from '../api'
  import utils from '../utils'
  import utility from '@/mixins/utility'
  
  import ActionButton from '../components/ActionButton'
  import ArticlesList from '../components/article/List'
  import BookContextMenu from '@/components/book/context-menu/Menu'
  import ClearAllModal from '../components/favourite/ClearAllModal'
  import Header from '../components/Header'
  import IssueCard from '../components/issue/Card'
  import Message from '../components/Message'
  import Note from '../components/note/Note'
  import Quote from '../components/quote/Quote'

  export default {
    name: 'FavouritesPage',

    mixins: [ utility ],

    components: {
      ActionButton,
      ArticlesList,
      BookContextMenu,
      ClearAllModal,
      Header,
      IssueCard,
      Message,
      Note,
      Quote
    },

    data: () => ({
        favs: {
            articles: [],
            books: [],
            issues: [],
            notes: [],
            quotes: []
        },
        tab: null
    }),

    mounted()
    {
        this.loadFavourites()
    },

    computed:
    {
        hasArticles()
        {
            return !_.isEmpty(this.favs.articles)
        },

        hasBooks()
        {
            return !_.isEmpty(this.favs.books)
        },

        hasFavs()
        {
            return (this.hasBooks || this.hasNotes || this.hasQuotes)
        },

        hasIssues()
        {
            return !_.isEmpty(this.favs.issues)
        },

        hasNotes()
        {
            return !_.isEmpty(this.favs.notes)
        },

        hasQuotes()
        {
            return !_.isEmpty(this.favs.quotes)
        },

        sortedQuotes()
        {
          var sorted = _.sortBy(this.favs.quotes, 'book.title')

          return sorted
        }
    },

    methods:
    {
        copyEmbedUrl()
        {
          const embedUrl = location.href + '/embed'
          this.copyToClipboard(embedUrl)
        },

        loadFavourites()
        {
          this.$store.commit('setLoading')

            Api.favourites.list({ with:['bookshelves'] })
               .then((res)=>{
                this.favs = res.data

                this.$store.commit('setLoading', false)
               })
        },

        onBookClick(book)
        {
            this.$router.push({ name: 'book', params: { bookId: book.id } })
        },

        onContextMenu(e, book)
        {
          this.$refs.contextMenu.show(e, book)
        },

        onIssueClick(issue)
        {
            this.$router.push({ name: 'issue', params: { issueId: issue.id } })
        },

        onNoteClick(note)
        {
          var owner = utils.methods.getModelType(note.owner_type)

          if(!owner)
            return
          
          switch(owner.name){
            case 'article':
                this.$router.push({ name: 'article', params: { articleId: note.owner_id } })
              break;
            case 'book':
                this.$router.push({ name: 'book', params: { bookId: note.owner_id } })
              break;
          }
        },

        openBook(quote)
        {
            /**
             * @todo check that quote is actually owned by a book.
             */
            this.$router.push({ name: 'book', params: { bookId: quote.owner_id } })
        },

        openClearAllModal()
        {
          this.$refs.clearAllModal.show()
        },

        exportData()
        {
          this.$store.commit('setLoading')

          Api.exports.generate('favs')
                     .then(()=>{
                      this.$router.push({ name: 'settings' })
                     })
                     .finally(()=>{
                      this.$store.commit('setLoading', false)
                     })
        },
    }
  }
</script>

<template>
  <div>
    <Header title="Settings" />

    <v-container fluid>
    
      <v-row>
        <v-col :cols="$vuetify.breakpoint.lgAndUp ? 4 : 12">
          <WidgetExport/>
        </v-col>
        
        <v-col></v-col>
        <v-col></v-col>

      </v-row>

    </v-container>

  </div>
</template>

<script>
  //import _ from 'lodash'
  //import Api from '@/api'
  
  import Header from '@/components/Header'
  import WidgetExport from '@/components/widgets/Export'

  export default {
    name: 'SettingsPage',

    components: {
      Header,
      WidgetExport
    },

    data: () => ({
        
    }),

    mounted()
    {
        
    },

    computed:
    {
      
    },

    methods:
    {
        
    }
  }
</script>

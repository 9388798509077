const   OpenAI = require('openai-api'),
		OPENAI_API_KEY = process.env.VUE_APP_OPENAI_API_KEY

const openai = new OpenAI(OPENAI_API_KEY)

const CONFIG = {
	MAX_TOKENS : 2049,
	PROMPT_COMFORT_ZONE : 500 
}

export { CONFIG }
export default openai
<template>
  <div>
    <NetworkGraph :nodes="mapNodes()"
                  :links="mapLinks()"
                  v-if="hasNodes"
                  @node-click="onNodeClick"
                  :dragger="dragger"
                  ref="graph"/>

    <v-container v-else>
      <Message>
        Network is empty, please <strong class="pointer" @click="openNodeModal">add a node</strong>.
      </Message>
    </v-container>

    <GraphFilters ref="graphFilters"/>

    <ActionButton>
      <v-btn fab dark @click="openNodeModal">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn fab dark @click="zoomOut">
        <v-icon>mdi-magnify-minus-outline</v-icon>
      </v-btn>
      <v-btn fab dark @click="zoomIn">
        <v-icon>mdi-magnify-plus-outline</v-icon>
      </v-btn>
      <v-btn fab dark @click="toggleFilters">
        <v-icon>mdi-filter</v-icon>
      </v-btn>
      <!--
      <v-btn fab dark @click="dragger=!dragger">
        <v-icon>mdi-drag-variant</v-icon>
      </v-btn>
      -->
    </ActionButton>

    <NodeModal ref="nodeModal"
               @selected="onNodeModalSelect"/>

  </div>
</template>

<style src="vue-d3-network/dist/vue-d3-network.css"></style>

<style type="text/css">
  .net { height: calc( 100vh - 64px ); }
</style>

<script>
  import _ from 'lodash'
  import Api from '../api'
  import Utility from '../utils'

  import ActionButton from '../components/ActionButton'
  import GraphFilters from '../components/network/GraphFilters'
  import Message from '../components/Message'
  import NetworkGraph from '../components/network/Graph'
  import NodeModal from '../components/network/NodeModal'
  
  export default {
    name: 'Network',

    components: {
      ActionButton,
      Message,
      GraphFilters,
      NetworkGraph,
      NodeModal
    },

    data: () => ({
      dragger: false,
      links: [],
      nodes: []
    }),

    mounted()
    {
      let modelId = this.$route.params.modelId,
          modelType = this.$route.params.modelType

      if( modelId && modelType )
        this.loadNodes( modelId, modelType )

      else
        this.$refs.nodeModal.show()
    },

    computed: {
      hasNodes()
      {
        return this.nodes.length
      },
      filters()
      {
        return this.$refs.graphFilters.getFilters()
      }
    },

    methods:
    {
        addLinks(links)
        {
          var merged = _.chain(this.links)
                        .concat(links)
                        .uniqBy('id')
                        .value()

          this.links = merged
        },

        addNodes(nodes)
        {
          var merged = _.chain(this.nodes)
                        .concat(nodes)
                        .uniqBy('id')
                        .value()

          this.nodes = merged
        },

        filteredLinks()
        {
          var filters = this.filters,
              links = this.links

          return _.filter(links, (link)=>{

            var show = true

            // link weight filter
            if(link.weight < filters.weight)
              show = false

            return show

          })
        },

        filteredNodes()
        {
          var links = this.filteredLinks(),
              sids = _.map(links, 'sid'),
              tids = _.map(links, 'tid'),
              linkedIds = _.uniq(sids.concat(tids)),
              nodeTypeFilter = _.map(this.filters.types, (name)=>{ return name.toLowerCase() }),
              filteredNodes = _.filter(this.nodes, (node)=>{

                var model = Utility.methods.getModelType(node.id).name,
                    show = true
                
                // hide orphan nodes
                if(linkedIds.indexOf(node.id) < 0)
                  show = false

                // applying node type filter
                if( nodeTypeFilter.length )
                  if( nodeTypeFilter.indexOf(model) < 0 )
                    show = false

                return show
              })

          return filteredNodes
        },

        loadNodes( modelId, modelType )
        {
          this.$store.commit('setLoading')

          Api.network.nodes(modelId, modelType)
             .then((res)=>{

              this.addNodes(res.data.nodes)

              this.addLinks(res.data.links)

              this.$store.commit('setLoading', false)

             })
             .catch((error)=>{

              console.error(error.response)

              this.$store.commit('growl', {
                color: 'error',
                text: error.response.data.message
              })

              this.$store.commit('setLoading', false)

             })
        },

        mapLinks()
        {
          var nodeIds = _.map( this.mapNodes(), 'id' ),
              links = _.filter(this.filteredLinks(), (link)=>{
                return ( nodeIds.indexOf(link.sid) >= 0 &&
                         nodeIds.indexOf(link.tid) >= 0 )
              })

          return links
        },

        mapNodes()
        {
          var nodes = this.filteredNodes()

          return nodes
        },

        onNodeClick(ev,node)
        {
          console.log(ev,node)

          let id = node.id.split('-')

          this.loadNodes( id[1], id[0] )
        },

        onNodeModalSelect(selected)
        {
          let modelId = selected.value,
              modelType = selected.model

          this.loadNodes( modelId, modelType )
        },

        openNodeModal()
        {
          this.$refs.nodeModal.show()
        },

        toggleFilters()
        {
          this.$refs.graphFilters.toggle()
        },

        zoomIn()
        {
          this.$refs.graph.zoomIn()
        },

        zoomOut()
        {
          this.$refs.graph.zoomOut()
        }
    }
  }
</script>

<template>

  <div v-if="isLoaded">

    <v-container fluid>
      
      <IssueRow :issue="issue">

        <div class="my-6">
          <span class="text-h5">Tags</span>
          <Tags model-type="issue" 
                :model-id="issue.id"/>

          <p class="text-h5 my-3 mb-0">Articles</p>
          <ArticlesList model-type="issue"
                        :model-id="issue.id"
                        ref="articlesList"
                        dense />
        </div>

      </IssueRow>  

    </v-container>

    <IssueTabs :issue="issue" ref="issueTabs"/>

    <ActionButton>
      <v-btn fab dark @click="openIssueModal">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn fab dark @click="openArticleModal">
        <v-icon>mdi-newspaper-plus</v-icon>
      </v-btn>
      <v-btn fab dark @click="openNoteModal">
        <v-icon>mdi-note</v-icon>
      </v-btn>
    </ActionButton>

    <IssueModal :issue="issue" 
               ref="issueModal"
               @save="onIssueSave"/>

    <ArticleModal :owner-id="issue.id" 
                  owner-type="issue"
                  ref="articleModal"
                  @save="onArticleSave"/>

    <NoteModal @save="onNewNote"
                ref="noteModal"
                :owner-id="issue.id"
                owner-type="issue"
                button-class="d-none"/>

  </div>

</template>

<script>

  import _ from 'lodash'

  import Api from '../api'

  import ActionButton from '../components/ActionButton'
  import ArticlesList from '../components/article/List'
  import ArticleModal from '../components/article/Modal'
  import IssueModal from '../components/issue/Modal'
  import IssueRow from '../components/issue/Row'
  import IssueTabs from '../components/issue/Tabs'
  import NoteModal from '../components/note/Modal'
  import Tags from '../components/tag/Tags'

  export default {
    name: 'Issue',

    components: {
      ActionButton,
      ArticlesList,
      ArticleModal,
      IssueModal,
      IssueRow,
      IssueTabs,
      NoteModal,
      Tags
    },

    mounted(){
      this.loadIssue()
    },

    methods: {

      /**
       * Load issue via API.
       */
      loadIssue()
      {
        let issueId = this.$route.params.issueId

        this.$store.commit('setLoading')

        Api.issues.one(issueId).then((res)=>{
          // res.data.links
          this.issue = res.data.data

          // set page title
          document.title = this.issue.title

          this.$store.commit('setLoading', false)
        })
      },

      onArticleSave()
      {
        this.$refs.articlesList.refresh()
      },

      onIssueSave(issue)
      {
        this.issue = issue
      },

      onNewNote()
      {
        this.$refs.issueTabs.fetchNotes()
      },

      openArticleModal()
      {
        this.$refs.articleModal.show()
      },

      openIssueModal()
      {
        this.$refs.issueModal.show()
      },

      openNoteModal()
      {
        this.$refs.noteModal.show()
      },

    },

    data: () => ({
      issue: {}
    }),

    computed: {
      isLoaded()
      {
        return !_.isEmpty(this.issue)
      }
    }
  }
</script>

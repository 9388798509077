<template>

  <div>

    <Header title="Chart" />

    <v-container fluid>

      <v-row align="start" v-if="loading && !books.length">
        <v-col cols="6" md="2" v-for="i in 6" :key="i">
          <v-sheet
            color="grey lighten-4"
            class="pa-3"
          >
            <v-skeleton-loader type="card" class="w-100"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>

      <Message v-if="!loading && !books.length">
        No books here.
      </Message>
      
      <v-row align="start">
      
        <v-col cols="6" md="2" v-for="(book) in books" :key="book.id">
      
          <BookCard :book="book" 
                    @click="goTo"
                    @contextmenu="onContextMenu"/>
      
        </v-col>
      
      </v-row>

      <!-- context menu -->
      <BookContextMenu ref="contextMenu"/>

    </v-container>

  </div>

</template>

<script>

  import Api from '../api'

  import InfiniteScroll from '../mixins/infinite-scroll'

  import BookContextMenu from '@/components/book/context-menu/Menu'
  import Header from '@/components/Header'
  import Message from '@/components/Message'

  export default {
    name: 'Chart',

    components: {
      BookContextMenu,
      Header,
      Message
    },

    mixins: [InfiniteScroll],

    mounted(){
      this.loadBooks()
    },

    methods: {

      goTo(book)
      {
        this.$router.push({ name: 'book', params: { bookId: book.id } })
      },

      /**
       * Load books to list via API.
       */
      loadBooks()
      {
        this.loading = true

        var page = (this.meta.current_page + 1)

        Api.books.chart({ 
          page: page, 
          per_page: 18,
          status: this.status,
          with: ['bookshelves']
        }).then((res)=>{
          
          this.books = this.books.concat(res.data.data)
          
          this.meta = res.data.meta

          this.loading = false

        })
      },

      onContextMenu(e, book)
      {
        this.$refs.contextMenu.show(e, book)
      },

      reset()
      {
        this.books = []
        
        this.meta = { current_page:0, last_page:0 }
      }

    },

    data: () => ({
      books: [],
      loading: false,
      meta: {
        current_page: 0,
        last_page: 0
      }
    }),

    computed: {
      hasMore()
      {
        return (this.meta.current_page < this.meta.last_page);
      }
    },

    watch: {
      infiniteScroll: {
         handler(infiniteScroll){
          if (infiniteScroll.bottom && this.hasMore)
            this.loadBooks()
         },
         deep: true
      },
      loading(status)
      {
        this.$store.commit('setLoading', status)
      }
    }
  }
</script>

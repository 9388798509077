<template>

  <div>
    
    <Header :title="website.title">
      <template v-slot>
        <v-chip color="primary">{{ website.articles_count }}</v-chip>
      </template>
      <template v-slot:subtitle v-if="website.url">
        <a :href="website.url" target="_blank"
           class="pl-3 text-h5">
          {{ website.url }}
        </a>
      </template>
    </Header>

    <v-list two-line v-if="isLoaded">

      <div v-for="(articles,letter) in groupedArticles" 
           :key="letter">

        <v-subheader>
          {{ letter }}
        </v-subheader>
      
        <v-list-item 
          v-for="article in articles" 
          :key="article.id"
          @click="goTo(article)">

          <v-list-item-content>
            <v-list-item-title>
              {{ article.title | uppercase }}
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>

      </div>

      <v-container v-if="!hasArticles">
        <v-alert text color="info">
          No articles here (yet!)
        </v-alert>
      </v-container>

    </v-list>

    <v-container v-else>
      <v-alert text color="info">
        No websites here (yet!)
      </v-alert>
    </v-container>

    <ActionButton>
      <v-btn fab dark @click="openWebsiteModal">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn fab dark @click="openArticleModal">
        <v-icon>mdi-newspaper-plus</v-icon>
      </v-btn>
      <v-btn fab dark @click="doScreenshot">
        <v-icon>mdi-monitor-screenshot</v-icon>
      </v-btn>
    </ActionButton>

    <WebsiteModal ref="websiteModal"
                  @save="onWebsiteModalSave"
                  :website="website"/>

    <ArticleModal ref="articleModal"
                  @save="onArticleModalSave"
                  owner-type="website"
                  :owner-id="website.id"/>

  </div>

</template>

<script>

  import _ from 'lodash'
  import axios from 'axios'
  import Api from '../api'
  import { mapMutations } from 'vuex'

  import ActionButton from '../components/ActionButton'
  import ArticleModal from '../components/article/Modal'
  import Header from '../components/Header'
  import WebsiteModal from '../components/website/Modal'

  export default {
    name: 'Websites',

    components: {
      ActionButton,
      ArticleModal,
      Header,
      WebsiteModal
    },

    mixins: [],

    mounted(){
      this.loadWebsite()
    },

    methods: {

      ...mapMutations([
        'growl',
        'setLoading'
      ]),

      goTo(article)
      {
        this.$router.push({ name: 'article', params: { articleId: article.id } })
      },

      /**
       * Load website details via API.
       */
      loadWebsite()
      {
        this.$store.commit('setLoading')

        let websiteId = this.$route.params.websiteId

        axios.all([
            Api.websites.one(websiteId),
            Api.websites.articles(websiteId)
          ])
          .then(axios.spread((...res)=>{

            let website = res[0].data.data,
                articles = res[1].data.data
            
            this.website = website

            document.title = website.title

            this.articles = articles

            this.$store.commit('setLoading', false)
          }))
      },

      openArticleModal()
      {
        this.$refs.articleModal.show()
      },

      openWebsiteModal()
      {
        this.$refs.websiteModal.show()
      },

      onArticleModalSave(article)
      {
        this.articles.push(article)
      },

      onWebsiteModalSave(website)
      {
        console.log(website)
      },

      doScreenshot()
      {
        this.setLoading()

        Api.websites.screenshot(this.website.id)
                    .then((res)=>{
                      console.log(res)
                      this.$store.commit('growl', {
                        color: 'success',
                        text: 'Screenshot aggiornato con successo.'
                      })
                    })
                    .catch((err)=>{
                      this.$store.commit('growl', {
                        color: 'error',
                        text: err.response.data.message
                      })
                    })
                    .finally(()=>this.setLoading(false))
      }

    },

    data: () => ({
      articles: {},
      website: {},
    }),

    computed: {
      hasArticles()
      {
        return !_.isEmpty(this.articles)
      },
      groupedArticles()
      {
        const groupedArticles = _.groupBy(this.articles,(article)=> {
          return article.title.toUpperCase().substring(0,1)
        })

        let sorted = {}

        _.chain(groupedArticles)
         .keys()
         .sort()
         .forEach((key)=>{
          sorted[key] = groupedArticles[key]
         })
         .value()

        return sorted
      },
      isLoaded()
      {
        return !_.isEmpty(this.website)
      }
    },

    watch: {
      
    }
  }
</script>

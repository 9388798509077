<template>

  <div>
    
    <Header title="Websites"></Header>

    <v-container>

      <v-row v-if="hasWebsites">
        <v-col :cols="$mq=='sm'?12:3"
               v-for="(website) in sortedWebsites"
               :key="website.id">
          <v-card @click="goTo(website)">
            <v-img
              class="white--text align-end"
              height="200px"
              :src="coverUrl(website)"
            >
              <v-card-title class="primary">
                {{ website.title | uppercase }}
                <v-chip class="ml-2" small 
                        v-html="website.articles_count"></v-chip>
              </v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>

      <v-alert text color="info" v-else>
        <h3 class="headline">No websites here (yet!)</h3>
      </v-alert>

    </v-container>

    <ActionButton>
      <v-btn fab dark @click="openWebsiteModal">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </ActionButton>

    <WebsiteModal ref="websiteModal"
                  @save="onModalSave"/>

  </div>

</template>

<script>

  import _ from 'lodash'
  import Api from '../api'
  import InfiniteScroll from '../mixins/infinite-scroll'

  import ActionButton from '../components/ActionButton'
  import Header from '../components/Header'
  import WebsiteModal from '../components/website/Modal'

  export default {
    name: 'Websites',

    components: {
      ActionButton,
      Header,
      WebsiteModal
    },

    mixins: [InfiniteScroll],

    mounted(){
      this.loadWebsites()
    },

    methods: {

      /**
       * Get cover url (and resize Cloudinary URLs)
       */
      coverUrl(website)
      {
        let url = website.cover_url
            url = url.replace('/image/upload/', '/image/upload/x_0,y_0,w_500,h_300,c_fill/')

            console.log(url)

        return url
      },

      goTo(website)
      {
        this.$router.push({ name: 'website', params: { websiteId: website.id } })
      },

      /**
       * Load websites to list via API.
       */
      loadWebsites()
      {
        this.$store.commit('setLoading')

        var page = this.pagination.current_page

        Api.websites.list({ page:page }).then((res)=>{
          var merged = this.websites.concat(res.data.data)
          
          this.websites = merged

          this.pagination = res.data.meta

          this.$store.commit('setLoading', false)
        })
      },

      onModalSave(website)
      {
        this.websites.push(website)
      },

      openWebsiteModal()
      {
        this.$refs.websiteModal.show()
      }

    },

    data: () => ({
      websites: [],
      pagination: { current_page:1 }
    }),

    computed: {
      hasWebsites()
      {
        return !_.isEmpty(this.websites)
      },
      sortedWebsites()
      {
        return _.orderBy(this.websites, 'title')
      }
    },

    watch: {
      infiniteScroll: {
         handler(infiniteScroll){
          if (infiniteScroll.bottom)
          {
            this.pagination.current_page++
            this.loadWebsites()
          }
         },
         deep: true
      }
    }
  }
</script>

<template>

  <v-container>
    
    <Quote v-if="quote" 
           :quote="quote"
           :book-details="true"/>

    <Loading :status="true" v-else/>

    <p class="text-center mt-5">
      <v-btn @click="fetchQuote" color="primary">
        GET A QUOTE
      </v-btn>
    </p>

  </v-container>

</template>

<script>

  import Api from '../api'

  import Loading from '../components/Loading'
  import Quote from '../components/quote/Quote'

  export default {
    name: 'RandomQuote',

    components: {
      Loading,
      Quote
    },

    mounted(){

      this.fetchQuote()

    },

    methods: {
      fetchQuote()
      {
        this.quote = null 

        Api.quotes.random()
                  .then((res)=>{
                    this.quote = res.data.data
                  })
      }
    },

    data: () => ({
      quote: null
    }),

    computed: {}
  }
</script>

<template>

  <div>
    
    <Header title="AI Writer"></Header>

    <!-- UI to choose AI inputs -->
    <v-container v-if="!isWriting">

      <Search :routing="false" 
              @selected="addItem"
              :autoexpand="false"/>

      <v-card v-if="items.length" color="grey lighten-4">
        <v-card-text>
          <chip v-for="(item,i) in items" 
                :key="i" 
                :item="item"
                @close="onChipClose"/>    
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text
                 @click="writeText">
            <v-icon class="mr-2">mdi-pen</v-icon>
            WRITE TEXT
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>

    </v-container>

    <!-- Writing UI -->
    <div v-else>

      <!-- handling error reponse -->
      <v-container v-if="errorMessage" class="mt-3">
        
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>

        <div class="text-center mt-5">
          <v-btn @click="isWriting = false">
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            BACK
          </v-btn>
        </div>

      </v-container>
      
      <!-- loading UI -->
      <v-container class="mt-3" v-else-if="!texts.length">
        <v-skeleton-loader
          type="list-item-three-line"
          v-for="i in apiConfig.n"
          :key="i"
        ></v-skeleton-loader>
      </v-container>

      <div v-else>

        <v-toolbar dense dark elevation="0">
          <v-btn text @click="isWriting = false">
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            BACK
          </v-btn>
          
          <v-spacer/>

          <v-btn text @click="writeText">
            <v-icon class="mr-2">mdi-refresh</v-icon>
            RETRY
          </v-btn>

          <v-menu dark :offset-y="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <v-icon class="mr-2">mdi-cog</v-icon>
                CONFIG
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Temperature ({{ apiConfig.temperature }})
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <v-slider
                      v-model="apiConfig.temperature"
                      max="2"
                      min="0"
                      thumb-label
                      step="0.1"
                      ticks
                    ></v-slider>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer/>
          
          <v-btn color="error" text @click="reset">
            <v-icon class="mr-2">mdi-trash-can</v-icon>
            RESTART
          </v-btn>
        </v-toolbar>

        <v-container class="mt-3">

          <v-card v-for="(choice,i) in texts" 
                  :key="i"
                  class="mb-5 lighten-5"
                  color="yellow">
            <v-card-text>
              {{ choice.text }}
            </v-card-text>
          </v-card>

        </v-container>

      </div>

    </div>
    
  </div>

</template>

<script>

  import _ from 'lodash'
  import Api from '../api'
  import OpenAI, { CONFIG } from '../plugins/openai'
  
  import Chip from '../components/writer/Chip'
  import Header from '../components/Header'
  import Search from '../components/Search'

  import SearchResult from '@/classes/searchResult'

  export default {
    name: 'Writer',

    components: {
      Chip,
      Header,
      Search
    },

    mixins: [],

    mounted()
    {
      this.checkQueryString()
    },

    methods: {

      checkQueryString()
      {
        var query = this.$route.query
        
        if( !query.with )
          return

        var decoded = JSON.parse(query.with)
        
        _.chain(decoded)
         .map((w)=>{
            return new SearchResult(w)
         })
         .each((w)=>{
          this.addItem(w)
         })
         .value()
      },

      addItem(item)
      {
        //if( !_.find(this.items, { model:item.model, value:item.value }) )
          this.items.push(item)
      },

      onChipClose(item)
      {
        this.items = _.without(this.items, item)
      },

      async getApiConfig( merge={} )
      {
        var maxTokens = CONFIG.MAX_TOKENS - merge.prompt.length

        if( maxTokens < 0 )
          maxTokens = CONFIG.PROMPT_COMFORT_ZONE

        return _.merge(this.apiConfig, merge, { maxTokens })
      },

      getPrompt( text )
      {
        var words = _.words(text),
            maxLength = CONFIG.MAX_TOKENS - CONFIG.PROMPT_COMFORT_ZONE,
            prompt = ""

        words.forEach((word)=>{

          word = `${word} `

          if( (prompt.length + word.length) < maxLength )
            prompt += word

        })

        return prompt
      },

      async writeText()
      {
        this.isWriting = true

        this.texts = []

        this.errorMessage = ''

        var res = await Api.write(this.items),
            prompt = this.getPrompt(res.data),
            config = await this.getApiConfig({ prompt })

        /**
         * @see https://beta.openai.com/docs/api-reference/completions/create
         */
        const gptResponse = await OpenAI.complete(config)
                                        .catch((error)=>{
                                          var msg = error.response.data.error.message

                                          this.errorMessage = msg
                                        })

        this.texts = gptResponse.data.choices
      },

      reset()
      {
        this.isWriting = false

        this.items = []

        this.texts = []

        this.errorMessage = ''
      }

    },

    data: () => ({
      items: [],
      isWriting: false,
      texts: [],
      errorMessage: '',
      apiConfig: {
                engine: 'davinci',
                prompt: '',
                maxTokens: 2049,
                temperature: 0.9,
                topP: 1,
                presencePenalty: 0,
                frequencyPenalty: 0,
                bestOf: 5,
                n: 5,
                stream: false,
                //stop: ['\n', "testing"]
            }
    }),

    computed: {
      
    },

    watch: {

    }
  }
</script>

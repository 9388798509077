<template>

  <div>

    <Header title="Newsletters" />

    <v-list two-line v-if="newsletters.length">

      <template v-for="(newsletter, index) in newsletters">
        <v-list-item :key="newsletter.id" 
                     @click="onClick(newsletter)">
          <template>
            <v-list-item-content>
              <v-list-item-title v-text="newsletter.title.toUpperCase()"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-chip color="primary" 
                      class="ma-2" 
                      v-html="newsletter.articles_count"></v-chip>
            </v-list-item-action>
          </template>
        </v-list-item>

        <v-divider
          v-if="index < newsletters.length - 1"
          :key="`sep${index}`"
        ></v-divider>
      </template>

      </v-list>

      <Message v-else>
        <h3 class="headline">No newsletters here (yet!)</h3>
      </Message>
    
    <!--
    <ActionButton icon="mdi-plus">
      <v-btn fab dark @click="openTagModal">
        <v-icon>mdi-label</v-icon>
      </v-btn>
    </ActionButton>

    <TagModal ref="tagModal" @save="onModalSave"/>
    -->

  </div>

</template>

<script>
  import Api from '@/api'

  //import ActionButton from '../components/ActionButton'
  import Header from '../components/Header'
  //import TagModal from '../components/tag/TagModal'
  
  export default {
    name: 'Tags',

    components: {
      //ActionButton,
      Header,
      //TagModal,
      //TagsList
    },

    data: () => ({
      loading: null,
      newsletters: []
    }),

    mounted()
    {
      this.load()
    },

    methods: {

      load()
      {
        this.$store.commit('setLoading')

        Api.newsletters.list({ sorts:'title', include:'articlesCount' }).then((res)=>{
          this.newsletters = res.data.data

          this.$store.commit('setLoading', false)
        })
      },

      onClick(newsletter)
      {
        this.$router.push({ name: 'newsletter', params: { newsletterId: newsletter.id } })
      },

      onModalSave()
      {
        //this.$refs.tagsList.loadTags()
      },

      openTagModal()
      {
        //this.$refs.tagModal.show()
      }

    },
  }
</script>

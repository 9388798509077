<template>
  <div>
    <Header :title="tag.name.toUpperCase()" />
    <div v-if="hasItems">
        <v-tabs
          background-color="grey"
          center-active
          dark
          v-model="tab"
        >
          <v-tab v-if="hasBooks">
            <v-icon class="mr-3">mdi-book</v-icon> 
            Books
          </v-tab>
          <v-tab v-if="hasIssues">
            <v-icon class="mr-3">mdi-newspaper-variant-outline</v-icon> 
            Magazines
          </v-tab>
          <v-tab v-if="hasQuotes">
            <v-icon class="mr-3">mdi-bookmark</v-icon> 
            Quotes
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="hasBooks">
            <v-container fluid>
                <v-row align="start">
                  <v-col cols="6" md="2" v-for="(book) in tag.books.data" :key="book.id">
                    <BookCard :book="book" />
                  </v-col>
                </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="hasIssues">
            <v-container fluid>
              <v-row align="start">
                <v-col cols="6" md="2" 
                       v-for="issue in tag.issues.data" 
                       :key="issue.id">
                  <IssueCard :issue="issue"
                             :height="250" />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="hasQuotes">
            <v-container fluid>
                <Quote v-for="quote in tag.quotes.data" 
                       :key="quote.id"
                       :quote="quote" 
                       book-details
                       without-menu />
            </v-container>
          </v-tab-item>
        </v-tabs-items>
    </div>

    <v-container v-else>
        <v-alert text color="info">
          <h3 class="headline">No content here (yet!)</h3>
        </v-alert>
    </v-container>

  </div>
</template>

<script>
  import _ from 'lodash'
  import Api from '@/api'
  
  import Header from '@/components/Header'
  import IssueCard from '@/components/issue/Card'
  import Quote from '@/components/quote/Quote'

  export default {
    name: 'TagPage',

    components: {
      Header,
      IssueCard,
      Quote,
    },

    data: () => ({
        tag: {
          name: '',
          books: { data:[] },
          quotes: { data:[] }
        },
        tab: null
    }),

    mounted()
    {
        this.loadTag()
    },

    computed:
    {
        hasBooks()
        {
            return !_.isEmpty(this.tag.books)
                   && !_.isEmpty(this.tag.books.data)
        },

        hasItems()
        {
            return (this.hasBooks || this.hasQuotes || this.hasIssues)
        },

        hasIssues()
        {
            return !_.isEmpty(this.tag.issues.data)
        },

        hasQuotes()
        {
            return !_.isEmpty(this.tag.quotes.data)
        }
    },

    methods:
    {
        loadTag()
        {
          let tagId = this.$route.params.tagId

          this.$store.commit('setLoading')

          Api.tags.one(tagId)
             .then((res)=>{
              
              this.tag = res.data.data

              // set page title
              document.title = `${this.tag.name} - Tags`

              this.$store.commit('setLoading', false)
             })
        },
    }
  }
</script>

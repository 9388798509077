<template>

  <div>

    <Header>Search</Header>

    <!-- Search fields -->
    <v-toolbar flat 
               color="grey lighten-5"
               class="bd-b">
      <v-text-field
        v-model="search"
        label="Search"
        hide-details
        prepend-icon="mdi-magnify"
        single-line
        :autofocus="true"
        @keyup.enter="doSearch"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-select
        v-model="models"
        :items="entities"
        label="Models"
        hide-details
        multiple
      ></v-select>
      <v-spacer></v-spacer>
      <v-btn depressed 
             color="primary"
             @click="doSearch"
             :loading="isLoading">
        Search
      </v-btn>
    </v-toolbar>

    <!-- Search results -->
    <v-container class="mt-5"
                 v-if="init">
    
      <v-progress-linear indeterminate v-if="isLoading"></v-progress-linear>

      <!-- Listing -->
      <div v-else>

        <v-alert type="warning"
                v-if="!paginator.meta.total">
          Non ci sono risultati da mostrare
        </v-alert>

        <v-alert type="info"
                 dense
                 v-else>
          Mostro {{ results.length }} di {{ paginator.meta.total }} risultati.
        </v-alert>

        <div v-for="(result,i) in results"
             :key="i">
          <v-divider v-if="i" class="my-8"></v-divider>
          <SearchResult :item="result"
                        class="pa-5">
          </SearchResult>
        </div>

        <v-pagination
          v-model="currentPage"
          class="my-4"
          :length="paginator.meta.last_page"
        ></v-pagination>
      
      </div>

    </v-container>

  </div>

</template>

<script>
  import _ from 'lodash'
  import Api from '../api'

  import search from '@/mixins/search'

  import Header from '../components/Header'
  //import Loading from '../components/Loading'
  import SearchResult from '../components/search/Result'

  import Result from '@/classes/searchResult'

  const entities = [ 'Articles', 'Authors', 'Books', 'Issues', 'Quotes', 'Tags', 'Topics' ]

  export default {
    name: 'SearchPage',

    mixins: [ search ],

    components: {
      Header,
      //Loading,
      SearchResult
    },

    mounted()
    {
      this.initFromQuerystring()
    },

    methods:
    {
      initFromQuerystring()
      {
        let s = this.$route.query.s
        
        if(!s)
          return
        
        this.search = s

        this.doSearch()
      },

      doSearch()
      {
        let search = this.search,
            models = _.map(this.models, (model)=>model.toLowerCase())

        if( !search.replaceAll(' ','').length ) return

        this.init = true

        this.isLoading = true

        Api.search(search, { models, page:this.currentPage }, true)
          .then(res => {
            this.paginator = res.data
            this.currentPage = res.data.meta.current_page
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      }
    },

    data: () => ({
      init: false,
      paginator: {},
      currentPage: 1,
      isLoading: false,
      search: '',
      models: [ 'Quotes' ] // entities
    }),

    computed: {
      entities: ()=> entities,
      results(){ return this.paginator.data ? this.paginator.data.map((item)=>new Result(item)) : [] }
    },

    watch: {
      currentPage()
      {
        this.doSearch()
      }
    }
  }
</script>

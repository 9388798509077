<template>
    <v-row
      class="pt-10 grey lighten-5"
      justify="center"
      style="height:100vh"
      no-gutters
    >
        <v-col lg="4">
            <v-card class="mx-auto mt-5 px-5 py-5">
                
                <h2 class="text-center">
                    <logo></logo>
                </h2>

                <message v-if="error" class="my-5">
                    {{ error }}
                </message>

                <v-form 
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    :loading="isLoading">
                    <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                        autocomplete="off"
                        :disabled="isLoading"
                        @keyup.enter="login"
                    ></v-text-field>

                    <v-text-field
                        v-model="password"
                        label="Password"
                        required
                        type="password"
                        autocomplete="off"
                        :disabled="isLoading"
                        @keyup.enter="login"
                    ></v-text-field>

                    <p class="text-center">
                        <v-btn color="primary" 
                               @click="login"
                               :loading="isLoading">
                            Login
                        </v-btn>
                    </p>
                </v-form>

            </v-card>
        </v-col>
    </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  import logo from '../components/Logo'
  import message from '../components/Message'
  
  export default {
    name: 'LoginPage',

    components: {
        logo,
        message
    },

    data: () => ({
        email: '',
        emailRules: [],
        error: '',
        isLoading: false,
        password: '',
        valid: false
    }),

    computed: {
        ...mapGetters([
          'isLogged'
        ])
    },

    mounted()
    {
        if(this.isLogged)
            this.$router.push({ name: 'home' })
    },

    methods:
    {
        login()
        {
            this.isLoading = true

            this.$store.dispatch('login', {
                email: this.email,
                password: this.password
            })
            .then(()=>{
                this.$router.push({ name: 'home' })
            })
            .catch((e)=>{
                this.error = e.response.data.message.join(' ')
            })
            .finally(()=>{
                this.isLoading = false
            })
        }
    }
  }
</script>
